import Cookies from 'js-cookie'

const TokenKey = 'Shui-Token'

export function getToken() {
  return Cookies.get(TokenKey)
}

export function setToken(token) {
  return Cookies.set(TokenKey, token)
}

export function removeToken() {
  return Cookies.remove(TokenKey)
}

export function setSession() {
  return Cookies.set('SESSION', 'kill me!!')
}
export function getSession() {
  return Cookies.get("SESSION")
}

