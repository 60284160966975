import request from '@/util/request'

export async function getCurCompany (isLogin, ipAddress) {
    return request({
        url: '/portal/current_company',
        method: 'get',
        params: {
            isLogin,
            ipAddress
        }
    })
}

export async function getCompanies () {
    return request({
        url: '/portal/companies',
        method: 'get',
        params: {
        }
    })
}
