<template>
  <el-dialog
      class="register-tip"
      title="提示"
      width="694px"
      :visible.sync="dialogTipVisible">
        <div class="content mb30">
          <span class="fw6 pb5" style="display: inline-block;">华师大学生请直接输入学号登录（初始密码同为学号，登录后请至个人中心修改密码） ，</span>
          <span>不要使用注册页面进行注册，以免影响后续水杉学习</span>
        </div>
        <el-button  plain @click="toLogin">返回登录</el-button>
        <el-button type="primary" @click="toRegister">继续注册</el-button>
      </el-dialog>  
</template>

<script>
export default {
  name: 'RegisterTip',
  data() {
    return {
      dialogTipVisible: false
    }
  },
  methods: {
    handleOpenDialog() {
      this.dialogTipVisible = true
    },
    handleCloseDialog() {
      this.dialogTipVisible = false
    },
    toLogin() {
      this.handleCloseDialog()
      this.$emit('toLogin')
    },
    toRegister() {
      this.handleCloseDialog()
      this.$emit('toRegister')
    }
  }
}
</script>

<style lang="scss" scoped>
.register-tip {
  ::v-deep .el-dialog {
    text-align: center;
    .el-dialog__header {
      padding-top: 45px;
      .el-dialog__title {
        color: #181717;
        font-size: 24px;
        font-weight: 600;
      }
    }
    .el-dialog__body {
      padding: 10px 50px 40px;
      .content {
        color: #333;
        text-align: left;
        span:last-child {
          color: #181717;
        }
      }
      .el-button {
        width: 120px;
        height: 44px;
        border-radius: 3px;
        &.is-plain {
          background: #fff;
          border-color: #13A81B;
          color: #13A81B;
        }
      }
    }
  }
}
</style>
