<template>
  <div class="footer">
    <div class="footer-container w1200">
      <div class="footer-content dfa">
        <div class="dfa">
          <div class="footer-serve">
            <div class="title fs16 fw5">水杉服务</div>
            <div class="serverList dfa">
              <a class="server-item footer-item-words fs14" target="_blank" :href="item.dictValue"
                 v-for="item in serverList" :key="item.dictValue">{{ item.dictLabel }}</a>
            </div>
          </div>
          <div class="footer-donate">
            <div class="title fs16 fw5">赞助单位</div>
            <div class="donateList dfa">
              <img class="donate-item mb10" v-for="item in donateList" :key="item" :src="item" alt="">
            </div>
          </div>
        </div>
        <div class="footer-contact">
          <div class="title fs16 fw5">关注或联系我们</div>
          <div class="official-accounts fl mr20">
            <img src="@/assets/img/index/official_accounts.png" alt="">
            <div class="footer-item-words fs12 mt5">数据学院公众号</div>
          </div>
          <div class="official-wechat">
            <img src="@/assets/img/index/official_wechat.png" alt="">
            <div class="footer-item-words fs12 mt5">水杉官方微信</div>
          </div>
          <div class="footer-item-words fs14 mt10">
            联系地址: 上海市普陀区中山北路3663号
          </div>
        </div>
      </div>
      <div class="copyright dfaj fs14 mt20">Copyright ©全民计算机科学教育研究中心 版权所有 备案号: 沪-20024652号-3</div>
    </div>
  </div>
</template>


<script>
import enums from '../util/enums';

export default {
  name: 'nav-footer',
  data() {
    return {
      serverList: enums.serverList,
      donateList: enums.donateList,
    }
  }
}
</script>
<style lang="scss" scoped>
.w1200 {
  width: 1200px;
  margin: 0 auto;
}

.footer {
  width: 100%;
  height: 307px;
  min-width: 1200px;
  background-color: #1A1A1C;

  .footer-content {
    padding-left: 25px;
    padding-right: 130px;
    justify-content: space-between;

    .footer-serve, .footer-donate, .footer-contact {
      padding-top: 31px;

      .title {
        font-family: PingFangSC-Medium;
        color: #FFFFFF;
        margin-bottom: 13px;
      }

      .footer-item-words {
        color: #ffffff;
        opacity: 0.7;
      }
    }

    .footer-serve {
      margin-right: 117px;

      .serverList {
        flex-direction: column;
        justify-content: space-between;
        height: 140px;
      }
    }

    .footer-donate {
      .donateList {
        width: 270px;
        justify-content: space-between;
        flex-wrap: wrap;

        .donate-item {
          width: 126px;
          height: 40px;
          border-radius: 8px;
        }
      }
    }

    .official-accounts, .official-wechat {
      text-align: center;

      img {
        width: 95px;
        height: 95px;
      }
    }
  }

  .copyright {
    height: 50px;
    color: #FFFFFF;
    background-color: rgba($color: #2B2B2B, $alpha: 0.6);
    // background-color: rgba(43, 43, 43, 0.6);
    font-family: PingFangSC-Regular;
  }
}
</style>