import request from '@/util/request'
//获取答题信息
export function getStudyList(params) {
  return request({
    url: '/portal/huhang/averageScore',
    method: 'get',
    params: params
  })
}

//获取打卡任务
export function getClockTaskList(params) {
  return request({
    url: '/portal/student/checkCurrent',
    method: 'get',
    params: params
  })
}

//获取历史记录
export function getTaskHistoryList(params) {
  return request({
    url: '/portal/student/checkHistory',
    method: 'get',
    params: params
  })
}

// 给助教发送消息
export function sendRobotMsg(params) {
  return request({
    url: 'teacher/teacher_admin/question/student/QA',
    method: 'get',
    params: params
  })
}

export function checkIn (params) {
  return request({
    url: '/portal/student/check',
    method: 'post',
    params: params
  })
}

/**
 * 根据课程获取学生打卡信息
 * @param courseId
 * @returns {AxiosPromise}
 */
export function getCheckHistoryListByCourse(courseId) {
  return request({
    url: '/portal/student/checkHistory/course',
    method: 'get',
    params: {courseId}
  })
}

/**
 * 根据用户的teachclass获取打卡签到考勤项
 * @param teachclassId
 * @returns {*}
 */
export function getCheckItemByTeachclassId(teachclassId) {
  return request({
    url: '/portal/student/check/item',
    method: 'get',
    params: {teachclassId}
  })
}


/**
 * 打卡签到
 * @param checkItemId
 * @returns {*}
 */
export function postCheckItemByCheckItemId(checkItemId) {
  return request({
    url: '/portal/student/check',
    method: 'post',
    params: {checkItemId}
  })
}