<template>
  <div class="assistant-box">
    <!--  TODO: 这个盒子实现了悬浮对话框的 UI ，后面可以直接用，现在先注释掉
      在有对话框后，目前的实现方案可能需要根据实际情况重构，例如CS101部分是否需要继续支持
      水杉集成？不需要的话，应将2个组件以v-if-else的形式存在于页面中
      -->
<!--    <div class="chatroom-box" v-show="showAssistantCode">-->
<!--      <div class="room-name-box">-->
<!--        <div class="room-name fs16 fw5">智能助教</div>-->
<!--      </div>-->
<!--      <div class="msg-list-box" ref="msg-list-box">-->
<!--        <div v-for="(item, index) in chatMsgList" :key="index">-->
<!--          <div class="msg-box-left dfa" v-if="item.source == 0">-->
<!--            <div class="head-pic mr10">-->
<!--              <img src="../../assets/img/tip/assistant_head_portrait.png" />-->
<!--            </div>-->
<!--            <div class="msg-detail">-->
<!--              <div class="send-time fs12 fw4">-->
<!--                {{ item.name }} {{ parseTime(item.sendTime, "{h}:{i}:{s}") }}-->
<!--              </div>-->
<!--              <div class="msg-content fs14 fw4">{{ item.msgContent }}</div>-->
<!--            </div>-->
<!--          </div>-->
<!--          <div class="msg-box-right dfa" v-if="item.source == 1">-->
<!--            <div class="msg-detail">-->
<!--              <div class="send-time fs12 fw4">-->
<!--                {{ item.name }} {{ parseTime(item.sendTime, "{h}:{i}:{s}") }}-->
<!--              </div>-->
<!--              <div class="msg-content fs14 fw4">{{ item.msgContent }}</div>-->
<!--            </div>-->
<!--            <div class="head-pic">-->
<!--              <img src="../../assets/img/tip/assistant_head_portrait.png" />-->
<!--            </div>-->
<!--          </div>-->
<!--        </div>-->
<!--      </div>-->
<!--      <div class="blank-area">-->
<!--        <input-->
<!--            @keyup.enter="sendMsg"-->
<!--            v-model="userMsg.msgContent"-->
<!--            placeholder="很高兴为您服务，请描述您的问题"-->
<!--        />-->
<!--        <div class="send-button cp fs14 fw5 dfaj" @click="sendMsg">发送</div>-->
<!--      </div>-->
<!--    </div>-->
    <!-- TODO: 修改这里 -->
    <div class="assistant smallTip cp" :title="currentAssistant.assistantName" @click.prevent.stop="clickAssistantButton">
      <img :src="currentAssistant.assistantLogoUrl" alt="" />
    </div>
  </div>
</template>

<script>
import {sendRobotMsg} from "@/api/center/center.js";
import {applyToken} from "@/api/oauth/oauth2";
import {getToken} from "@/util/auth";

export default {
  name: "Assistant",
  props: {
    showAssistantCode: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      CS101_COURSE_ID: "1540171183997247489",   // 新生第一课的课程 ID
      currentAssistant: {
        assistantName: "助教",
        assistantLogoUrl: require("../../assets/img/tip/assistant.png"),
        assistantServicePath: ""
      },
      chatMsgList: [
        {
          name: "智能助教",
          sendTime: new Date(),
          msgContent: "你好，我是水杉平台智能助教。",
          source: 0,
        },
      ],
      userMsg: {
        name: "",
        sendTime: new Date(),
        msgContent: "",
        source: 1,
      },
    };
  },
  computed: {
    courseId() {
      return this.$route.query.courseId;
    }
  },
  watch: {
    $route(to, from) {
      // 路由变化，就把助教弹窗关闭
      this.showAssistantCode = false;
    },
    courseId: {
      handler(newVal, oldVal) {
        this.handleCourseIdChange(newVal);
      },
      immediate: true
    }
  },
  methods: {
    clickAssistantButton() {
      // this.$emit("clickAssistantButton");
      // this.resetUserMsg();
      switch (this.currentAssistant.assistantName) {
        case "新生第一课智能助教":
          window.open(this.currentAssistant.assistantServicePath);
        case "杉杉智能编程助手":
          // 杉杉要带 oauth2 token 访问，所以多一步
          this.getShanshanAssistantServicePath().then(res => {
            window.open(res);
          });
      }
    },
    sendMsg() {
      let userMsg = this.userMsg;
      this.resetUserMsg();
      userMsg.sendTime = new Date();
      if (userMsg.msgContent.trim()) {
        this.chatMsgList.push(userMsg);
        this.$nextTick(() => {
          this.$refs["msg-list-box"].scrollTop =
              this.$refs["msg-list-box"].scrollHeight;
        });
        let params = {
          type: 1,
          key: userMsg.msgContent,
        };
        // 调发送消息接口
        sendRobotMsg(params)
            .then((res) => {
              if (res.respCode == "20000") {
                let robotMsg = {
                  name: "智能助教",
                  sendTime: new Date(),
                  msgContent: res.data,
                  source: 0,
                };
                this.chatMsgList.push(robotMsg);
                this.$nextTick(() => {
                  this.$refs["msg-list-box"].scrollTop =
                      this.$refs["msg-list-box"].scrollHeight;
                });
              }
            })
            .catch((err) => {});
      }
    },
    resetUserMsg() {
      this.userMsg = {
        name: "",
        sendTime: null,
        msgContent: "",
        source: 1,
      };
    },
    handleCourseIdChange(currentCourseId) {
      if (currentCourseId === this.CS101_COURSE_ID) {
        this.currentAssistant = {
          assistantName: "新生第一课智能助教",
          assistantLogoUrl: require("../../assets/img/tip/cs101_assistant_logo.jpg"),
          assistantServicePath: "https://cs101asst.shuishan.net.cn/"
        }
      } else {
        this.currentAssistant = {
          assistantName: "杉杉智能编程助手",
          assistantLogoUrl: require("../../assets/img/tip/shanshan_assistant_logo.png"),
          assistantServicePath: "https://assistant.shuishan.net.cn"  // unused
        }
      }
    },
    async getShanshanAssistantServicePath() {
      if (getToken()) {
        const res = await applyToken('gpt_assistant');
        console.log(res);
        return "https://assistant.shuishan.net.cn" + "/?token=" + res.data.access_token;
      } else {
        return "https://assistant.shuishan.net.cn";
      }
    }
  },
  mounted() {
  },
  destroyed() {},
};
</script>

<style lang="scss" scoped>
.assistant-box {
  .smallTip {
    padding: 8px;
    // padding-top: 5px;
    box-sizing: border-box;
    width: 56px;
    height: 56px;
    text-align: center;
    background-color: #fff;
    box-shadow: 0px 0px 20px 0px rgba(0, 0, 0, 0.1);
    margin-top: 20px;
    border-radius: 20%;
    img {
      width: 26px;
      height: 26px;
      vertical-align: middle;
    }
    .content {
      font-family: PingFangSC-Regular;
      color: #13A81B;
      letter-spacing: 0;
    }
  }
  .assistant {
    margin-top: 0;
    // 助教
    background-color: #ffffff;
    .content {
      color: #13A81B;
    }
    img {
      width: 100%;
      height: 100%;
    }
  }
  .chatroom-box {
    position: absolute;
    z-index: 199;
    width: 360px;
    height: 520px;
    background: #f5f6f9;
    border-radius: 8px;
    box-sizing: border-box;
    box-shadow: 0px 0px 20px 0px rgba(0, 0, 0, 0.1);
    left: -375px;
    top: -420px;
    .room-name-box {
      width: 360px;
      height: 54px;
      background: #353735;
      border-radius: 8px 8px 0px 0px;
      .room-name {
        height: 22px;
        font-family: PingFangSC-Medium;
        color: #ffffff;
        letter-spacing: 0;
        padding: 16px 16px;
      }
    }
    .msg-list-box {
      width: 360px;
      height: 335px;
      overflow: auto;
      .msg-box-left {
        margin: 20px auto 10px 17px;
        .head-pic {
          background: #f5f6f9;
          width: 40px;
          height: 40px;
          img {
            width: 100%;
            height: 100%;
          }
        }
        .msg-detail {
          .send-time {
            height: 17px;
            font-family: PingFangSC-Regular;
            color: #9b9ea3;
            letter-spacing: 0;
            margin-bottom: 8px;
          }
          .msg-content {
            max-width: 160px;
            background: #ffffff;
            border-radius: 0px 8px 8px 8px;
            font-family: PingFangSC-Regular;
            color: #333333;
            letter-spacing: 0;
            padding: 10px 10px;
          }
        }
      }
      .msg-box-right {
        margin: 20px 17px 10px auto;
        .msg-detail {
          margin: 0px 10px auto auto;
          .send-time {
            height: 17px;
            font-family: PingFangSC-Regular;
            color: #9b9ea3;
            letter-spacing: 0;
            margin-bottom: 8px;
            text-align: right;
          }
          .msg-content {
            max-width: 160px;
            background: #13a81b;
            border-radius: 8px 0px 8px 8px;
            font-family: PingFangSC-Regular;
            color: #ffffff;
            letter-spacing: 0;
            padding: 10px 10px;
          }
        }
        .head-pic {
          background: #f5f6f9;
          width: 40px;
          height: 40px;
          margin: 0px 0px 0px 0px;
          img {
            width: 100%;
            height: 100%;
          }
        }
      }
    }
    .blank-area {
      width: 360px;
      height: 130px;
      background: #ffffff;
      border-radius: 0px 0px 8px 8px;

      input {
        margin: 16px 16px 0px 16px;
        width: 260px;
        max-height: 100px;
        border: 0px;
        white-space: wrap;
      }
      input::-webkit-input-placeholder {
        font-family: PingFangSC-Regular;
        font-size: 14px;
        color: #cecece;
        letter-spacing: 0;
        font-weight: 400;
      }
      .send-button {
        position: absolute;
        width: 48px;
        height: 30px;
        background: #13a81b;
        border-radius: 6px;
        font-family: PingFangSC-Medium;
        color: #ffffff;
        letter-spacing: 0;
        right: 10px;
        bottom: 10px;
      }
    }
  }
}
</style>