
import Driver from 'driver.js' // 页面引导
import 'driver.js/dist/driver.min.css'

const driver = new Driver({
  className: 'scoped-class', // 包装类名
  animate: true, // 是否开启动画
  stageBackground: '#fff', // 突出元素的背景颜色 根据UI调整
  opacity: 0.75, // 遮罩层不透明度 （0表示仅弹出且不覆盖
  padding: 0, // 边距
  allowClose: false, // 点击遮罩层是否关闭
  overlayClickNext: false, // 叠加点击时是否移动到下一步
  doneBtnText: '完成', // 结束按钮文字 （最后一步的下一步
  closeBtnText: '结束导览', // 关闭按钮的文字
  nextBtnText: '下一步', // 下一步按钮的文字
  prevBtnText: '上一步', // 上一步按钮的文字
  showButtons: true, // 是否展示按钮
  keyboardControl: true, // 允许通过键盘进行控制（esc退出，箭头键移动
  onNext: (data) => { // 引导进入下一步触发
    // console.log(data)
  },
  onReset: (data) => { // 引导结束触发
    // console.log(data)
    // 引导完成之后回到页面顶部
    // backToTop()
  },
})

export const backToTop = () => {
  let top = document.documentElement.scrollTop || document.body.scrollTop
    let timer = setInterval(() => {
      let ispeed = Math.floor(-top / 5)
      top = document.documentElement.scrollTop = document.body.scrollTop = top + ispeed
      if (top <= 1) {
        clearInterval(timer)
      }
    }, 20)
}
export default driver;