<template>
  <div class="help-menu-box">
    <div class="help-menu" v-show="showHelpMenuCode">
      <div class="page-guide-box menu-button-box dfaj" @click="handleHelp">
        <img src="../../assets/img/tip/page_guide.png" />
        <div class="button-content fs14 fw4 ml10">页面导览</div>
      </div>
      <!-- TODO: 修改这里 -->
      <div
          class="problem-feedback-box menu-button-box dfaj"
          @click="toFeedback"
      >
        <img src="../../assets/img/tip/problem_feedback.png" />
        <div class="button-content fs14 fw4 ml10">问题反馈</div>
      </div>
      <div class="user-manual-box menu-button-box dfaj" @click="handleOpenUse">
        <img src="../../assets/img/tip/user_manual.png" />
        <div class="button-content fs14 fw4 ml10">使用手册</div>
      </div>
    </div>
    <div class="smallTip cp" @click.prevent.stop="clickHelpMenuButton">
      <img src="../../assets/img/tip/help.png" alt="" />
      <div class="content fs12 fw4">帮助</div>
    </div>
  </div>
</template>

<script>
import FeedbackDialog from "../../pages/Login/FeedbackDialog.vue";
export default {
  name: "HelpMenu",
  components: {
    FeedbackDialog,
  },
  props: {
    showHelpMenuCode: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      helpMenCode: false,
      qqGroupLink: 'http://qm.qq.com/cgi-bin/qm/qr?_wv=1027&k=wwdblXDV-wja3abPhJjQ5L5nqtvWI7wM&authKey=cH4bGkc7ecDCdGNNnlDNzlrkIVEKHAGfovhU7btlhvx691whlt5iYeXBTa5%2B%2B42L&noverify=0&group_code=494311366'
    };
  },
  methods: {
    clickHelpMenuButton() {
      this.$emit("clickHelpMenuButton")
    },
    handleHelp() {
      this.eventBus.$emit(this.$route.name);
    },
    //问题反馈
    toFeedback() {
      // this.$refs.feedbackDialog.openFeedback = true;
      // this.$nextTick(() => {
      //   this.$refs.feedbackDialog.feedbackFormClear();
      // });
      const userConfirmed = window.confirm("问题反馈请加入水杉在线平台用户群：494311366\n（点击确认自动跳转到加群链接）");
      if (userConfirmed) { window.open(this.qqGroupLink); }

    },
    //使用手册
    handleOpenUse() {
      window.open("https://help.shuishan.net.cn/#/", "_blank");
    },
  },
  watch: {
    $route(to, from) {
      // 路由变化，就把帮助弹窗关闭
      this.showHelpMenuCode = false;
    },
  },
  mounted() {},
  destroyed() {},
};
</script>

<style lang="scss" scoped>
.help-menu-box {
  .smallTip {
    padding: 8px;
    // padding-top: 5px;
    box-sizing: border-box;
    width: 56px;
    height: 56px;
    text-align: center;
    background-color: #fff;
    box-shadow: 0px 0px 20px 0px rgba(0, 0, 0, 0.1);
    margin-top: 20px;
    border-radius: 20%;
    img {
      width: 26px;
      height: 26px;
      vertical-align: middle;
    }
    .content {
      font-family: PingFangSC-Regular;
      color: #13A81B;
      letter-spacing: 0;
    }
  }
  .help-menu {
    position: absolute;
    z-index: 199;
    width: 139px;
    height: 125px;
    background: #ffffff;
    box-shadow: 0px 0px 20px 0px rgba(0, 0, 0, 0.1);
    border-radius: 8px;
    padding: 10px 14px;
    box-sizing: border-box;
    left: -159px;
    top: 25px;
    .menu-button-box {
      height: 36px;
      border-radius: 6px;
      .button-content {
        height: 20px;
        font-family: PingFangSC-Regular;
        font-size: 14px;
        color: #666666;
        letter-spacing: 0;
        font-weight: 400;
      }

      img {
        width: 14px;
        height: 16px;
      }
      &:hover {
        background: rgba(19, 168, 27, 0.05);
        border-radius: 6px;
        .button-content {
          height: 20px;
          font-family: PingFangSC-Regular;
          color: #13a81b;
          letter-spacing: 0;
        }
      }
    }
    .page-guide-box {
      &:hover {
        img {
          content: url("../../assets/img/tip/page_guide_select.png");
        }
      }
    }
    .problem-feedback-box {
      &:hover {
        img {
          content: url("../../assets/img/tip/problem_feedback_select.png");
        }
      }
    }
    .user-manual-box {
      &:hover {
        img {
          content: url("../../assets/img/tip/user_manual_select.png");
        }
      }
    }
  }
}
</style>