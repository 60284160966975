<template>
  <div class="sidebar-component-box" id="sidebarComponents">
    <assistant ref="assistant" :showAssistantCode="showAssistantCode" @clickAssistantButton="clickAssistantButton"/>
    <help-menu ref="help-menu" :showHelpMenuCode="showHelpMenuCode" @clickHelpMenuButton="clickHelpMenuButton"/>
    <to-top ref="to-top"/>
  </div>
</template>

<script>
import Assistant from "@/components/SidebarComponents/Assistant.vue";
import HelpMenu from "@/components/SidebarComponents/HelpMenu.vue";
import ToTop from "@/components/SidebarComponents/ToTop.vue";
export default {
  name: "SidebarComponents",
  components: {
    Assistant,
    HelpMenu,
    ToTop
  },
  data() {
    return {
      showAssistantCode: false,
      showHelpMenuCode: false
    }
  },
  methods: {
    clickAssistantButton() {
      this.showAssistantCode = !this.showAssistantCode;
      this.showHelpMenuCode = false;
      this.resetUserMsg();
    },
    clickHelpMenuButton() {
      this.showHelpMenuCode = !this.showHelpMenuCode;
      this.showAssistantCode = false;
    }
  },
  mounted() {},
  destroyed() {},
};
</script>

<style lang="scss" scoped>
.sidebar-component-box {
  z-index: 99;
  position: fixed;
  right: 20px;
  bottom: 30px;
}
</style>