<template>
  <div class="smallTip cp toTop dfaj" @click="backToTop">
    <img src="../../assets/img/tip/to_top.png" alt="" />
  </div>
</template>

<script>
import { backToTop } from "../../util/driver";
export default {
  methods: {
    backToTop() {
      backToTop();
    }
  }
}
</script>

<style lang="scss" scoped>
.sidebar-component-box {
  .smallTip {
    padding: 8px;
    // padding-top: 5px;
    box-sizing: border-box;
    width: 56px;
    height: 56px;
    text-align: center;
    background-color: #fff;
    box-shadow: 0px 0px 20px 0px rgba(0, 0, 0, 0.1);
    margin-top: 20px;
    border-radius: 20%;
    &.toTop {
      // padding-top: 8px;
      img {
        width: 26px;
        height: 26px;
      }
    }
    img {
      width: 26px;
      height: 26px;
      vertical-align: middle;
    }
    .content {
      font-family: PingFangSC-Regular;
      color: #13A81B;
      letter-spacing: 0;
    }
  }
}
</style>