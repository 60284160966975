import request from '@/util/request'

const BASE_URL = '/user/oauth/client'

export async function applyToken (clientId) {
  return request({
    url: BASE_URL,
    method: 'post',
    data: {
      clientId
    }
  })
}

export async function getInfoWithToken (data) {
  return request({
    url: '/user/oauth/info',
    method: 'post',
    data
  })
}
