import Vue from 'vue'
ready ();
function ready() {
  if(/Android|Windows Phone|webOS|iPhone|iPod|SymbianOS|BlackBerry/i.test(navigator.userAgent)){  //测试当前是哪个手机系统，可根据业务需要选择
    console.log('手机')
    console.log(Vue)
    Vue.prototype.isEquipment = "mobile"
  }else if(/iPad/i.test(navigator.userAgent)){ // ipad
    Vue.prototype.isEquipment = "ipad"
    console.log('ipad')
  } else {
    Vue.prototype.isEquipment = "pc"
  }
}