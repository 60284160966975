<template>
  <div>
    <el-dialog
      class="forget-dialog"
      title="找回密码"
      :visible.sync="dialogEditTenantsVisible"
      :before-close="handleClose"
      :close-on-click-modal="closeOnClickModal"
    >
      <div class="dfaj">
        <div
          @click="handleChangeEmail"
          class="forget-title fs14 mr70 cp"
          :class="activeName == 'a' ? 'active-forget-title' : ''"
        >
          邮箱找回
        </div>
        <div
          @click="handleChangePhone"
          class="forget-title fs14 cp"
          :class="activeName == 'b' ? 'active-forget-title' : ''"
        >
          手机号找回
        </div>
      </div>
      <div v-if="activeName == 'a'" class="dfaj">
        <el-form ref="emailForm" :model="emailForm" :rules="emailRules">
          <el-form-item prop="mail">
            <el-input
              class="login_form_input"
              v-model="emailForm.mail"
              type="text"
              auto-complete="off"
              clearable
              placeholder="请输入注册填写的邮箱"
            >
            </el-input>
          </el-form-item>
          <el-form-item style="width: 300px">
            <el-button
              size="medium"
              type="primary"
              class="login_button_pw"
              @click.native.prevent="handleFindEmail"
            >
              <span style="color: #ffffff" class="fs14 fw6">确认</span>
            </el-button>
          </el-form-item>
        </el-form>
      </div>
      <div v-if="activeName == 'b'" class="dfaj">
        <el-form ref="phoneForm" :model="phoneForm" :rules="phoneRules">
          <el-form-item prop="phone" class="dfaj">
            <el-input
              class="login_form_input"
              v-model="phoneForm.phone"
              type="text"
              maxlength="11"
              auto-complete="off"
              clearable
              placeholder="请输入手机号码"
            >
            </el-input>
          </el-form-item>
          <el-form-item prop="code" class="dfaj">
            <el-input
              class="login_code_input mr10"
              v-model="phoneForm.code"
              type="text"
              maxlength="6"
              auto-complete="new-password"
              placeholder="验证码"
            />
            <el-button
              v-if="showSendCode_rg"
              class="code_button"
              size="medium"
              type="primary"
              @click.native.prevent="handleSendCode"
            >
              <span style="color: #ffffff" class="fs14">发送验证码</span>
            </el-button>

            <el-button
              v-if="!showSendCode_rg"
              class="code_button"
              size="medium"
              type="primary"
              disabled
            >
              <span style="color: #ffffff" class="fs14"
                >{{ count_rg }}秒后重发</span
              >
            </el-button>
          </el-form-item>
          <el-form-item style="width: 300px">
            <el-button
              size="medium"
              type="primary"
              class="login_button_pw"
              @click.native.prevent="handleFindPhone"
            >
              <span style="color: #ffffff">确认</span>
            </el-button>
          </el-form-item>
        </el-form>
      </div>
    </el-dialog>
    <el-dialog 
      class="forget-dialog"
      :visible.sync="dialogMessageVisible"
      :close-on-click-modal="closeOnClickModal">
      <div class="message-dialog mt10">
        <img src="@/assets/img/message_success.png" class="mb20" alt="">
        <div class="title mb20 fs18 fw5">{{showMessage.title}}</div>
        <div class="content">{{showMessage.content}}</div>

        <el-button type="primary" plain @click="login">重新登录</el-button>
      </div>
    </el-dialog>
  </div>
</template>
<script>
import {
  handleResetPhone,
  handleResetEmail,
  confirmResetPhone
} from "@/api/login/login.js";
import { setSession, getSession } from "@/util/auth";
export default {
  name: "ForgetPasswordDialog",
  data() {
    return {
      closeOnClickModal: false,
      dialogEditTenantsVisible: false,
      dialogMessageVisible: false,
      count_rg: 60,
      showSendCode_rg: true,
      activeName: "a",
      reg: /^1[3|4|5|6|7|8|9][0-9]\d{8}$/,
      timer_rg: null,
      phoneForm: {
        phone: "",
        code: "",
      },
      emailForm: {
        mail: "",
      },
      emailRules: {
        mail: [
          {
            required: true,
            message: "请输入邮箱",
            trigger: "blur",
          },
        ],
      },
      phoneRules: {
        phone: [
          {
            required: true,
            message: "请输入手机号",
            trigger: "blur",
          },
          {
            pattern: /^1[3|4|5|6|7|8|9][0-9]\d{8}$/,
            message: "请输入正确的手机号码",
            trigger: "blur",
          },
        ],
        code: [
          {
            required: true,
            message: "请输入验证码",
            trigger: "blur",
          },
        ],
      },
      messageSuccessTip: [
        {
          title: '密码重置成功',
          content: '华师大学生密码重置为学号，注册人员为手机号'
        },
        {
          title: '邮件发送成功',
          content: '邮件已发送成功，请去邮箱查收'
        }
      ],
      showMessage: {}
    };
  },
  mounted() {},
  methods: {
    // 关闭弹窗
    handleClose() {
      this.dialogEditTenantsVisible = false;
      this.phoneForm = {
        phone: "",
        code: "",
      }
      this.emailForm = {
        mail: "",
      }
    },
    // 打开弹窗
    handleOpenDialog() {
      this.dialogEditTenantsVisible = true;
    },
    handleChangeEmail() {
      this.activeName = "a";
    },
    handleChangePhone() {
      this.activeName = "b";
    },
    //找回手机号
    handleFindPhone() {
      if (!this.reg.test(this.phoneForm.phone)) {
        this.$message.error("请输入正确的手机号");
        return;
      }
      if (!this.phoneForm.code || this.phoneForm.code.length != 6) {
        return this.$message.error('请输入6位验证码')
      }
      confirmResetPhone(this.phoneForm).then(res => {
        if (res.respCode == '20000') {
          // 密码重置成功了
          this.handleClose()
          this.showMessage = this.messageSuccessTip[0]
          this.dialogMessageVisible = true
        } else {
          this.$message.error(res.respMsg)
        }
      }).catch(err => {})
    },
    //找回邮箱
    handleFindEmail() {
      this.$refs.emailForm.validate(valid => {
        if(valid) { // 通过校验
          if (!getSession()) {
            setSession()
          }
          handleResetEmail(this.emailForm).then(res => {
            if (res.respCode == '20000') {
              this.handleClose()
              this.showMessage = this.messageSuccessTip[1]
              this.dialogMessageVisible = true
            } else {
              this.$message.error(res.respMsg)
            }
          }).catch(err => {})
        }
      })
    },
    //发送验证码
    handleSendCode() {
      var phone = this.phoneForm.phone;
      if (!this.reg.test(phone)) {
        this.$message.error("请输入正确的手机号");
        return;
      }
      this.sendMsgCode(phone);
      if (!this.timer_rg) {
        this.showSendCode_rg = false;
        this.timer_rg = setInterval(() => {
          if (this.count_rg > 0 && this.count_rg <= 60) {
            this.count_rg--;
          } else {
            clearInterval(this.timer_rg);
            this.timer_rg = null;
            this.showSendCode_rg = true;
          }
        }, 1000);
      }
    },
    //发送短信验证码
    sendMsgCode(phone) {
      let params = {
        phone,
      };
      handleResetPhone(params)
        .then((res) => {
          this.$message({
            message: "验证码已发送",
            type: "success",
          });
        })
        .catch((err) => {
          let res = JSON.parse(JSON.stringify(err.response)).data
          let message = res.respMsg
          if(message.includes("Required String parameter 'phone' is not present")){
            this.$message({
              message: "手机号无对应用户，请尝试邮箱找回或联系管理员绑定手机号。",
              type: "error",
            });
          }
          // console.log('6666',err);
        });
    },
    //重置发送验证码倒计时
    resetMessageSendTimer() {
      clearInterval(this.timer_rg);
      this.timer_rg = null;
      this.showSendCode_rg = true;
    },
    login() {
      this.$emit('toLogin')
    }
  },
};
</script>
<style lang="scss">
.forget-dialog {
  .el-dialog {
    width: 400px !important;
    height: 420px !important;
  }
  .el-dialog__header {
    text-align: center;
    padding-top: 45px;
    .el-dialog__title {
      color: #181717;
      font-size: 24px;
      font-weight: 600;
    }
  }
  .forget-title {
    color: #666666;
    padding-bottom: 6px;
    border-bottom: 2px solid rgba($color: #000000, $alpha: 0);
    margin-bottom: 20px;
    &.active-forget-title {
      color: #181717;
      font-weight: 600;
      border-bottom-color: #13a81b;
    }
  }
  .login_form_input {
    width: 300px !important;
  }
  .login_code_input {
    width: 181px !important;
  }
  .login_button_pw {
    width: 300px;
    height: 44px;
    border-radius: 3px;
    margin-top: 18px;
  }
  .code_button {
    height: 40px !important;
  }
  .el-input__inner::placeholder {
    font-size: 14px;
    color: #181717;
  }
  .message-dialog {
    text-align: center;
    img {
      width: 79.64px;
      height: 79.64px;
    }
    .title {
      color: #181717;
    }
    .content {
      color: #333333;
      margin-bottom: 46px;
    }
    .el-button.is-plain {
      width: 154px;
      height: 44px;
      background: #fff;
      border-radius: 3px;
      border-color: #13A81B;
      color: #13A81B;
    }
  }
}
</style>