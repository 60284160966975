<template>
  <el-dialog v-loading="loading" :visible.sync="openFeedback" :destroy-on-close="true" center width="694px" class="feedback-box" :close-on-click-modal="false" append-to-body>
    <div class="title fw6 fs24 mb40">水杉意见反馈</div>
    <el-form ref="feedbackForm" :model="feedbackForm" :rules="feedbackRules">
      <el-form-item prop="feedtype" label="问题分类">
        <el-radio-group v-model="feedbackForm.feedtype">
          <el-radio v-for="item in questionType" :key="item.dictValue" :label="item.dictLabel"></el-radio>
        </el-radio-group>
      </el-form-item>
      <el-form-item label="" v-show="typeChild" label-width="66px">
        <el-checkbox-group v-model="feedbackForm.typeChild">
          <el-checkbox name="typeChild" v-for="item in typeChild" :key="item" :label="item"></el-checkbox>
        </el-checkbox-group>
      </el-form-item>
      <el-form-item prop="title" label="问题标题" class="lh40">
        <el-input v-model="feedbackForm.title" placeholder="问题标题"></el-input>
      </el-form-item>
      <el-form-item prop="content" label="问题详情">
        <el-input type="textarea" v-model="feedbackForm.content" placeholder="问题详情"></el-input>
      </el-form-item>
      <el-form-item prop="pic" label="图片上传">
        <el-upload
          class="avatar-uploader"
          action="https://www.shuishan.net.cn/api/portal/feedback_upload"
          :show-file-list="!imageUrl"
          :limit="1"
          :on-exceed="handleExceed"
          :on-success="handleAvatarSuccess"
          :before-upload="beforeAvatarUpload">
          <img v-if="imageUrl" :src="imageUrl" class="avatar">
          <i v-else class="el-icon-plus avatar-uploader-icon"></i>
        </el-upload>
      </el-form-item>
      <el-form-item prop="contact" label="联系方式" class="lh40">
        <el-input v-model="feedbackForm.contact" placeholder="请输入联系方式"></el-input>
      </el-form-item>
      <el-form-item class="feedback-btns">
        <el-button type="primary" @click="submitFeedBack">提交反馈</el-button>
      </el-form-item>
    </el-form>
  </el-dialog>  
</template>

<script>
import {
  handleFeedBack
} from "@/api/login/login.js"
import enums from '@/util/enums.js'

export default {
  name: 'FeedbackDialog',
  props: {
  },
  data() {
    const validateFeedType = (rule, value, callback) => {
      if (!value) {
        return callback(new Error('请选择问题分类'));
      } else {
        callback()
      }
    }
    const validateTitle = (rule, value, callback) => {
      if (!value) {
        return callback(new Error('请输入问题标题'));
      } else {
        callback()
      }
    }
    const validateContent = (rule, value, callback) => {
      if (!value) {
        return callback(new Error('请输入问题详情'));
      } else {
        callback()
      }
    }
    const validatePic = (rule, value, callback) => {
      if (!this.imageUrl || this.imageUrl.length <= 0) {
        return callback(new Error('请提交截图帮助我们快速的帮助到您!'));
      } else {
        callback()
      }
    }
    const validateContact = (rule, value, callback) => {
      if (!value) {
        return callback(new Error('请输入联系邮箱'));
      } else if (value.search(/^\w+((-\w+)|(\.\w+))*\@[A-Za-z0-9]+((\.|-)[A-Za-z0-9]+)*\.[A-Za-z0-9]+$/) == -1) {
        return callback(new Error('请填写正确的邮箱'))
      } else {
        callback()
      }
    }
    return {
      questionType: enums.questionType, // 问题分类
      openFeedback: false, // 是否打开dialog
      feedbackForm: {
        typeChild: []
      },
      feedbackRules: {
        feedtype: [
          { 
            validator: validateFeedType,
            trigger: "change", 
          }
        ],
        title: [
          { 
            validator: validateTitle,
            trigger: "change", 
          }
        ],
        content: [
          { 
            validator: validateContent,
            trigger: "change", 
          }
        ],
        pic: [
          {
            validator: validatePic,
            trigger: 'change'
          }
        ],
        contact: [
          { 
            validator: validateContact,
            trigger: "change", 
          }
        ]
      },
      imageUrl: '',
      loading: false
    }
  },
  computed: {
    typeChild() {
      // 问题分类选中非最后一个
      let flag = this.questionType.find(v => v.dictLabel == this.feedbackForm.feedtype)
      if (flag && flag.children) {
        this.feedbackForm.typeChild = [] // 切换的时候清除选择多选
        return flag.children
      } else {
        return null
      }
    }
  },
  methods: {
    feedbackFormClear() {
      this.feedbackForm = {
        typeChild: []
      };
      this.imageUrl = null
      this.$nextTick(() => {
        this.$refs.feedbackForm.clearValidate()
      })
    },
    // 图片上传相关
    handleAvatarSuccess(res, file) {
      // console.log(res, file)
      if (res.respCode == '20000') {
        this.feedbackForm.filepath = res.data
      } else {
        return this.$message.error(res.respMsg)
      }
      this.imageUrl = URL.createObjectURL(file.raw);
    },
    beforeAvatarUpload(file) {
      const isLt2M = file.size / 1024 / 1024 < 5;
      if (!isLt2M) {
        this.$message.error('上传图片大小不能超过 5MB!');
      }
      return isLt2M;
    },
    handleExceed(files, fileList) {
      this.$message.warning(`只可上传1张图片`);
    },
    // 提交反馈
    submitFeedBack()  {
      this.$refs.feedbackForm.validate(valid => {
        if (valid) { // 通过校验
          // console.log('valid')
          let params = JSON.parse(JSON.stringify(this.feedbackForm))
          params.feedtype = (params.typeChild && params.typeChild.length > 0)?params.feedtype + ',' + params.typeChild.join(): params.feedtype
          let formData = new FormData()
          formData.append('feedtype', params.feedtype)
          formData.append('title', params.title)
          formData.append('content', params.content)
          formData.append('filepath', params.filepath)
          formData.append('contact', params.contact)
          this.loading = true
          handleFeedBack(formData).then(res => { // 接口是提交表单数据
            this.loading = false
            // console.log(res)
            if (res.respCode == '20000') {
              this.$message.success('开发者已经收到您的信息，请等待邮件回复')
              this.openFeedback = false
            } else {
              return this.$message.error(res.respMsg)
            }
          }).catch(err => {
            this.loading = false
          })
        }
      })
    },
  },
  mounted() {}
}
</script>

<style lang="scss" scoped>
.mb40 {
  margin-bottom: 40px;
}
.feedback-box {
  ::v-deep .el-dialog__body {
    padding: 15px 60px 40px 50px;
    .title {
      color: #181717;
      text-align: center;
    }
    .lh40 {
      line-height: 40px;
    }
    .el-form-item {
      margin-bottom: 20px;
      &.feedback-btns {
        margin: 0;
        margin-top: 30px;
        text-align: center;
        .el-button {
          width: 120px;
          height: 44px;
          border-radius: 3px;
        }
      }
    }
    .el-form-item__label {
      line-height: unset;
      color: #181717;
      font-family: PingFangSC-Regular;
      padding-right: 10px;
    }
    .el-form-item__content {
      line-height: unset;
      .el-form-item__error { // 错误提示
        padding-left: 66px;
      }
      &>div {
        width: 518px;
        // 单选框
        &.el-radio-group {
          .el-radio {
            margin-right: 24px;
            &:last-child {
              margin: 0;
            }
            .el-radio__label {
              padding-left: 6px;
            }
          }
        }
        // 上传
        &.avatar-uploader .el-upload {
          background: #F5F6F9;
          border: 1px dashed rgba(220,223,230,1);
          border-radius: 6px;
          cursor: pointer;
          position: relative;
          overflow: hidden;
        }
        .avatar-uploader-icon {
          font-size: 28px;
          color: #A7A9BA;
          width: 100px;
          height: 100px;
          line-height: 100px;
          text-align: center;
        }
        .avatar {
          width: 100px;
          height: 100px;
          display: block;
        }
      }
    }
    .el-input__inner{
        font-family: PingFangSC-Regular;
    }
    .el-textarea__inner{
        font-family: PingFangSC-Regular;
    }
  }
}
</style>