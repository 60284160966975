<template>
  <div class="index">
    <nav-home></nav-home>
  </div>
</template>
<script>
import NavHome from './Home/index.vue'
  export default{
    name:'index',
    components:{
      NavHome
    },
    data(){
      return {
      }
    },
    mounted(){
    },
    methods:{
    }
  }
</script>
<style lang="scss" scoped>
  @import './../assets/scss/config.scss';
  @import './../assets/scss/mixin.scss';
  .index{
    // overflow: hidden;
  }
</style>