import request from '@/util/request'
// 登录
export function handleLogin(params) {
  return request({
    url: '/sys/login',
    method: 'post',
    data: params
  })
}
// 华师大统一登录
export function handleLoginByEcnu(params) {
  return request({
    url: `/user/public_db/login?code=${params}`,
    method: 'post',
  })
}
//发送验证码
export function handleSendCode(params) {
  return request({
    url: `/portal/send_code?phone=${params.phone}`,
    method: 'post',
    data: params
  })
}
//注册账号
export function handleRegister(params) {
  return request({
    url: `/portal/check_code?phone=${params.phone}&code=${params.code}&password=${params.password}`,
    method: 'post',
    data: params
  })
}

//退出登录
export function handleLoginOut(params) {
  return request({
      url: '/user/remove_token',
      method: 'delete',
      params: params
  })
}

//忘记手机号码发送
export function handleResetPhone(params) {
  return request({
    url: '/portal/reset/phone',
    method: 'post',
    params: params
  })
}
export function confirmResetPhone(params) {
  return request({
    url: '/portal/reset/phone',
    method: 'get',
    params: params
  })
}

// 提出反馈
export function handleFeedBack(params) {
  return request({
    url: '/portal/feedback',
    method: 'post',
    data: params
  })
}

// 邮箱找回
export function handleResetEmail(params) {
  return request({
    url: '/api/portal/reset/mail' + '?mail=' + params.mail,
    method: 'post'
  })
}