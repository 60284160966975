import Vue from 'vue'
import '@/assets/icons';
import Element from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
import router from './router'
import '@/assets/scss/element-variables.scss'
import VueCookie from 'vue-cookie'
import store from './store'
import App from './App.vue'
Vue.use(Element, { zIndex: 3000 });
import { parseTime, selectDictLabel, selectDictLabels, addZero,configCountDownString } from "@/util/index";
import '@/assets/style/global.scss'
//XSS攻击
import myxss from './util/xssWhiteList.js';
import * as echarts from 'echarts';
import '@/util/ready.js'
import eventBus from '@/util/eventBus.js';
import Driver from '@/util/driver.js';
Vue.prototype.$xss = (val) => {
    return myxss.process(val);
}
Vue.config.productionTip = false;
Vue.use(VueCookie);
Vue.prototype.parseTime = parseTime
Vue.prototype.configCountDownString = configCountDownString
Vue.prototype.selectDictLabel = selectDictLabel
Vue.prototype.selectDictLabels = selectDictLabels
Vue.prototype.addZero = addZero
Vue.prototype.echarts = echarts
Vue.prototype.eventBus = eventBus
Vue.prototype.Driver = Driver
new Vue({
    store,
    router,
    el: '#app',
    render: h => h(App),
}).$mount('#app')