import request from '@/util/request'

// 修改密码
export function usersPassword(param) {
  return request({
    url: '/user/users/password',
    method: 'put',
    data: param
  })
}

//获取个人信息
export function getUserInformation() {
  return request({
    url: '/sys/users/current',
    method: 'get',
    // params: params
  })
}

// 发送手机验证码
export function handleSendCode(params) {
  return request({
    url: '/portal/reset/sendPhone',
    method: 'post',
    params: params
  })
}

// 发送邮箱验证码
export function emailSendCode(params) {
  return request({
    url: '/portal/reset/sendMail',
    method: 'post',
    params: params
  })
}

// 解绑/更换手机号提交
export function checkPhoneCode(params) {
  return request({
    url: '/portal/reset/check_phone_code',
    method: 'post',
    params: params
  })
}

// 更换邮箱提交
export function checkMailCode(params) {
  return request({
    url: '/portal/reset/check_mail_code',
    method: 'post',
    params: params
  })
}

// 获取邮箱
export function getMail(params) {
  return request({
    url: '/portal/reset/getMail',
    method: 'post',
    params: params
  })
}

// 获取手机号
export function getPhone(params) {
  return request({
    url: '/portal/reset/getphone',
    method: 'post',
    params: params
  })
}

// 获取所属单位
export function getCompany(params) {
  return request({
    url: '/super/company',
    method: 'get',
    params: params
  })
}

//获取积分
export function getIntegral(params) {
  return request({
    url: '/teacher/course/sum_liveness',
    method: 'get',
    params: params
  })
}