<template>
  <div class="system-container">
    <div class="notice-wrapper">
      <div
        class="notice-bottom pt20 pb20 fs16 cp"
        v-for="(item,index) in list"
        :key="index"
        :class="isLogin?'notice-bottom-login':''"
      >
        <div class="pb6" :class="item.status == 0 ?'':'c999999'">
          {{ item.title }}
        </div>
        <div class="dfal jsb">
          <div class="notice-time">
            {{ item.time }}
          </div>
          <div v-if="activeName == 'a'" :class="item.status==0?'no-look-detail':'c999999'" class="dfal">
            查看详情
            <svg-icon
              icon-class="icon_chevron"
              className="arrow-right"
            />
          </div>
          <div v-if="activeName == 'b'" class="dfal" :class="item.status == 0 ?'':'c999999'">
            <svg-icon icon-class="icon_circle_left" className="icon-check-circle" v-if="item.status == 0"/>
            {{ item.status == 0 ? "标记已读" : "已读" }}
          </div>
        </div>
      </div>
    </div>
    <pagination
      :page="page"
      :limit="pageSize"
      :total="total"
      :hidden="total <= 0"
      @pagination="handleCurrentChange"
    />
  </div>
</template>
<script>
import Pagination from "@/components/Pagination";
export default {
  name: "SystemNotification",
  props: {
    list: {
      type: Array,
      default: [],
    },
    activeName: {
      type: String,
      default: "",
    },
    isLogin: {
      type: Boolean,
      default: false,
    }
  },
  components: {
    Pagination,
  },
  data() {
    return {
      page: 1,
      pageSize: 16,
      total: 4,
    };
  },
  mounted() {},
  methods: {
    //分页
    handleCurrentChange() {},
  },
};
</script>
<style lang="scss" scoped>
.system-container {
  .notice-wrapper {
    padding-bottom: 20px;
    .notice-bottom {
      width: 100%;
      border-bottom: 1px solid #e4e7ed;
      color: #303133;
      .notice-time {
        color: #999999;
      }
      &.notice-bottom-login {
        font-size: 14px;
      }
    }
  }
  .no-look-detail {
    color: #13A81B;
  }
  .arrow-right {
    width: 16px;
    margin-left: 11px;
  }
  .icon-check-circle {
    width: 18px;
    margin-right: 5px;
  }
}
</style>